button:focus {
  outline: 1px solid #858585;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}
.margin20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://uploads-ssl.webflow.com/6443797b2dbb5540710c396a/646f5375e2678f57654139ad_headerbgfinal-min.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0%;
  padding: 65px 0;
  position: relative;
}

@media screen and (max-width: 650px) {
  .header {
    background-image: url("https://uploads-ssl.webflow.com/6443797b2dbb5540710c396a/646f5375e2678f57654139ad_headerbgfinal-min.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0%;
    padding: 65px 0;
  }
}

.header h1 {
  margin: 0;
  color: #e6e6e6;
  text-transform: uppercase;
  text-align: center;
}

.header p {
  color: #e6e6e6;
}
.custom-shape-divider-bottom-1685016464 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1685016464 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 149px;
}

.custom-shape-divider-bottom-1685016464 .shape-fill {
  fill: #fff;
}

/** For mobile devices **/
@media (max-width: 767px) {
  .custom-shape-divider-bottom-1685016464 svg {
    width: calc(100% + 1.3px);
    height: 50px;
  }
}

/** For mobile devices **/
@media (min-width: 768px) {
  .mobileonly {
    display: none;
  }
}

.circles-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.circle {
  position: relative;
  border: 1px solid white;
  width: 2rem;
  height: 2rem;
  margin: 0;
  border-radius: 50%;
  background-color: #1a2131;
}
.circle.active::before {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: 25%;
  background-color: white;
  content: "";
  display: block;
  box-shadow: 0 0 5px 1px white;
}

.col1 {
  margin-left: 12px;
}

.line {
  flex: 1;
  height: 1px;
  width: 10vw;
  background-color: #ccc;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 0 0 0 white;
}

.line.active {
  box-shadow: 0 0 5px 1px white;
}

.currentContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 10px;
  background-color: #fff;
  min-height: 70vh;
  overflow: hidden;
  padding-bottom: 100px;
}

button {
  border: none;
  cursor: pointer;
}
.button-primary {
  background: #1a2131;
  padding: 24px 80px;
  text-transform: uppercase;
  color: #e6e6e6;
  font-weight: bold;
  min-width: 300px;
  margin: 12px;
  font-size: 1.1rem;
  border: none;
  text-decoration: none;
}
.button-secondary.small {
  font-size: 1rem;
  padding: 12px 40px;
}
.button-secondary {
  text-decoration: none;
  background: #d7d7d7;
  padding: 24px 80px;
  text-transform: uppercase;
  color: #1a2131;
  font-weight: bold;
  min-width: 300px;
  margin: 12px;
  font-size: 1.1rem;
}
@media screen and (max-width: 480px) {
  .button-primary,
  .button-secondary {
    padding: 24px 30px;
  }
}
@media screen and (max-width: 440px) {
  .col1 {
    font-size: 12px;
  }
  .button-secondary.small {
    font-size: 15px;
    padding: 8px;
    white-space: nowrap;
  }
}
.button-container {
  justify-content: center;
  display: flex;
  margin: 12px;
}
@media screen and (max-width: 768px) {
  .button-container {
    flex-direction: column;
  }
}
.introHeading {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  color: #5e5e5e;
}
.boldcaps.smaller {
  font-size: 1.6rem;
}
.boldcaps {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2rem;
  color: #1a2131;
}
.celltitle {
  font-size: 14px;
  background-color: #efe4a6;
}
.introTextContainer {
  /*width: 80%;*/
}
.disclaimer-banner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  background-color: #232b3b;
  padding: 10px;
  bottom: 0px;
  z-index: 777777;
  width: 100%;
  color: whitesmoke;
}
.modalTriggerText {
  background: none;
  color: white;
  text-decoration: underline;
}
.product-name a {
  color: white;
  background-color: #252b3b;
  text-decoration: none;
  padding: 8px;
}
.results-column.averill .product-name a {
  background-color: #ffffff;
  color: #1a2131 !important;
}
.banner-top {
  position: fixed;
  background-color: #232b3b;
  padding: 10px;
  top: 0px;
  z-index: 777777;
  width: 100%;
  display: flex;
  color: whitesmoke;
  justify-content: space-between;
}
.nostylelink {
  color: inherit;
  text-decoration: none;
}
.margintop50 {
  margin-top: 50px;
}
.nostylelink:hover {
  text-decoration: underline;
}
.marginright {
  margin-right: 10px;
}
.marginleft {
  margin-left: 10px;
}
.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 0;
}
.label-container {
  margin-bottom: 6px;
}
.inputGroup label {
  margin-right: 8px;
}
.inputGroup input {
  padding: 10px 20px;
  background-color: #d7d7d7;
  border-color: transparent;
  width: 260px;
}
.percentinput:focus-visible {
  outline-width: 1px;
  outline-offset: -2px;
  outline-style: solid;
}
.currencyinput:focus-visible {
  outline-width: 1px;
  outline-offset: -2px;
  outline-style: solid;
}
.total-display {
  background-color: #d7d7d7;
  padding: 20px 30px;
  color: #1a2131;
  font-weight: bold;
  box-shadow: 0px 4px 14px rgba(187, 187, 187, 0.25);
  border-color: transparent;
  font-size: 1.5rem;
  margin: 12px;
  border-radius: 4px;
}
.number-input {
  font-size: 1.15rem;
}

.hide {
  display: none !important;
}

.playbtn {
  z-index: 5000;
  height: 80px;
  width: 80px;
  background-color: transparent;
  background-image: url("https://uploads-ssl.webflow.com/6443797b2dbb5540710c396a/651686abec4ab15b3b6ab589_playbtn.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.vidOverlay {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputs-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.inputWrapper {
  display: flex;
  position: relative;
}
.currencyUSD {
  padding: 10px;
  background-color: #242938;
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  color: #efebcd;
}
.results-column-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100px;
}
.product-title {
  font-weight: 600;
  color: white;
}
.display-results {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.results-container {
  display: flex;
  column-gap: 2px;
  max-width: 90%;
}
@media screen and (max-width: 400px) {
  .results-container {
    max-width: 100%;
  }
}
.results-column {
  background-color: #c9c9c9;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .results-column {
    padding: 10px;
  }
}
.results-column.averill {
  background-color: #273865;
  margin-top: -10px;
}
.results-table {
  border-top: 2px solid white;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.results-table tr td {
}
.results-column.averill .product-name {
  margin: 35px 0;
}
.results-table tr:nth-child(odd) {
  background-color: #dbdbdb;
}
.results-table tr:nth-child(even) {
  background-color: #f0f0f0;
}
.product-name {
  font-size: 14px;
  color: white;
  line-height: 0px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.disclaimer {
  font-size: 0.7rem;
}
.date-column {
  font-size: 14px;
  color: rgb(75, 75, 75);
  text-align: center;
  padding: 5px;
}
.percentage-column {
  font-size: 16px;
  text-align: center;
  padding: 5px;
}
.happiness-chart {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.responsive-img {
  width: 100%;
  height: auto;
}
.red {
  color: #c00000 !important;
}
.legend-container {
  width: 25%;
}
.slider-container {
  width: 100%;
}

.legend-clients {
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 46px;
  text-align: right;
  margin-top: 50px;
}
.legend-clients span {
  font-size: 20px;
}
.ReactModal__Content.ReactModal__Content--after-open {
  inset: 0 !important;
}
@media screen and (max-width: 1200px) {
  .legend-clients {
    gap: 5px;
    line-height: 36px;
  }
}
@media screen and (max-width: 600px) {
  .legend-clients {
    gap: 30px;
    line-height: 15px;
    text-align: left;
    margin-top: 70px;
  }
  .legend-clients span {
    font-size: 15px;
  }
}
.semi-bold {
  font-weight: 500;
}
.bold {
  font-weight: 600;
}
.legend-values {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  line-height: 31px;
  text-align: right;
}
.legend-values span {
  font-size: 1rem;
}
@media screen and (max-width: 900px) {
  .legend-values span {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 600px) {
  .legend-values {
    row-gap: 19px;
    line-height: 15px;
  }
}
@media screen and (max-width: 480px) {
  .legend-values {
    row-gap: 15px;
    line-height: 15px;
    text-align: left;
    font-size: 12px;
  }
  .legend-values span {
    font-size: 0.7rem;
  }
}
.goldtext {
  color: #7f6000;
}
.transparent {
  background-color: transparent !important;
}
.green {
  color: #548235;
}
.small-text {
  font-size: 1rem !important;
  font-weight: 400 !important;
}
.spinner {
  margin-bottom: 50px;
}
.spinner-top {
  margin-top: 50px;
}
.highlighted {
  border: 1px solid black;
  background-color: #c4c4c4;
}
ul.splide__pagination.splide__pagination--ltr {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.splide__pagination li {
  list-style-type: none;
  pointer-events: auto;
  flex-grow: 1;
  height: 12px;
}
button.splide__pagination__page {
  background: grey;
  width: 100%;
  height: 100%;
}
button.splide__pagination__page:focus {
  outline: none;
  outline-offset: 0;
}
button.splide__pagination__page:active {
  transform: scale(1);
}

button.splide__pagination__page.is-active {
  background: #283863;
}
.input-wrapper {
  display: flex;
  align-items: center;
  background-color: #d7d7d7;
  box-shadow: 0px 4px 14px rgba(187, 187, 187, 0.25);
}
.percenticon {
  padding: 10px;
  background-color: #242938;
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  color: #efebcd;
}
.stubbed-p {
  max-width: 800px;
}
h4.subbed-h4 {
  margin-bottom: 0;
}
.player-wrapper {
  margin-top: 20px;
  position: relative;
  padding-top: 36.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 70%;
}
@media screen and (max-width: 1200px) {
  .player-wrapper {
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .player-wrapper {
    width: 100%;
  }
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

button.splide__arrow.splide__arrow--prev {
  transform: rotate(180deg);
}
.splide__arrow {
  margin: 5px;
}
.splide__arrow {
  align-items: center;
  justify-content: center;
  background: #ccc;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  top: 50%;
  z-index: 1;
  display: flex;
  align-content: center;
}
.splide__arrows.splide__arrows--ltr {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -60px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .splide__arrows.splide__arrows--ltr {
    position: absolute;
    bottom: auto;
    width: auto;
    right: 0;
    top: -54px;
  }
}
@media screen and (max-width: 767px) {
  .wacky-margin {
    margin-top: 90px;
  }
  h4.subbed-h4 {
  }
}
/*.input-wrapper.percentage{
  position: relative;
}
.input-wrapper.percentage:after{
  content: "%";
  position: absolute;
  background-color: #232938;
  width: 40px;
  top: 0;
  right: -5px;
  color: white;
  padding: 16px;
  font-size: 23px;
  border-radius: 0 12px 12px 0;
  line-height: 20px;
}*/

.form-header-hide-btn,
.form-header-close-btn {
  display: none !important;
  background: none !important;
}

.defaultBtn {
  display: none !important;
}
.lightbox {
  z-index: 70000 !important;
}
.displayblock {
  display: block !important;
}
